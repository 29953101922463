import { extendTheme } from '@chakra-ui/react'
import '@fontsource/courier-prime';

const theme = extendTheme({
  fonts: {
    heading: `'Courier Prime', monospace`,
    body: `'Courier Prime', monospace`,
  },
})

export default theme