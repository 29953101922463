import './App.css';
import { Image, ChakraProvider, Button, Flex, Spacer, VStack, Text, Highlight, Link, HStack } from '@chakra-ui/react'
import Heart from './assets/heart.png'
import Remilio from './assets/basedladys.png'

import theme from './theme'

function App() {

  const ca = '0x9947afba10a6e96f9bd05998003b149d46a08ac6'

  return (
    <ChakraProvider theme={theme}>
      <Flex px={5} py={5} alignItems={'center'}>
        <Image src={Heart} maxH={30}></Image>
        <Spacer />
        <Link href={`https://app.uniswap.org/swap?outputCurrency=${ca}&chain=base`}>
          <Button bgColor={'black'} color={'white'} size={'lg'}>TRADE $LADYS</Button>
        </Link>
      </Flex>
      <VStack px={5} py={5} >
        <Image src={Remilio} maxW={{ base: '170%', md: '1000' }}></Image>
        <Spacer />
        <Spacer />
        <Spacer />
        <Spacer />
        <Spacer />
        <Spacer />
        <Text fontSize={{ base: 'lg', md: '2xl' }} as='b' textAlign={'center'}>
          {/* <Highlight
            query='888 PFP COLLECTION BUILT WITH DN404 TECHNOLOGY'
            styles={{ px: '2', py: '1', bg: '#0052ff', color: 'white' }}
          > */}
            888 PFP COLLECTION BUILT WITH DN404 TECHNOLOGY
          {/* </Highlight> */}
        </Text>
        <Spacer />
        <Spacer />
        <Text fontSize={{ base: 'lg', md: '2xl' }} as='b'>
          <Highlight
            query='THERE IS ONLY BASE. I LOVE YOU.'
            styles={{ px: '2', py: '1', bg: '#0052ff', color: 'white' }}
          >
            THERE IS ONLY BASE. I LOVE YOU.
          </Highlight>
        </Text>
        <Spacer />
        <Spacer />
        <Spacer />
        <Text fontSize={{ base: 'sm', md: 'xl' }}>{ca}</Text>
        <Spacer />
        <Spacer />
        <Spacer />
        <HStack>
          <Text fontSize={{ base: 'sm', md: 'xl' }} as='u'><Link href="https://twitter.com/BasedLadys">TWITTER</Link></Text>
          <Spacer />
          <Spacer />
          <Text fontSize={{ base: 'sm', md: 'xl' }} as='u'><Link href={`https://dexscreener.com/base/${ca}`}>CHART</Link></Text>
          <Spacer />
          <Spacer />
          <Text fontSize={{ base: 'sm', md: 'xl' }} as='u'><Link href={`https://opensea.io/assets/base/0xbc4e4da567386c01b515df28d171021639ae860e`}>MARKETPLACE</Link></Text>
        </HStack>
      </VStack>
    </ChakraProvider>
  );
}

export default App;
